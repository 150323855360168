import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Partner from "../views/Partner.vue";
import About from "../views/About.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import Faq from "../views/Faq.vue";
import Howitworks from "../views/Howitworks.vue";
import Contact from "../views/Contact.vue";
import Career from "../views/Career.vue";
import Investor from "../views/Investor.vue";
import Help from "../views/Help.vue";
import CodeScreen from "../views/CodeScreen.vue";
Vue.use(VueRouter);

const routes = [
     {
          path: "/",
          name: "Home",
          component: Home,
     },
     {
          path: "/partner",
          name: "Partner",
          component: Partner,
     },
     {
          path: "/about",
          name: "About",
          component: About,
     },
     {
          path: "/career",
          name: "Career",
          component: Career,
     },
     {
          path: "/investor",
          name: "Investor",
          component: Investor,
     },
     {
          path: "/privacy",
          name: "Privacy",
          component: Privacy,
     },
     {
          path: "/terms",
          name: "Terms",
          component: Terms,
     },
     {
          path: "/faq",
          name: "FAQ",
          component: Faq,
     },
     {
          path: "/contact",
          name: "Contact",
          component: Contact,
     },
     {
          path: "/howitworks",
          name: "How",
          component: Howitworks,
     },
     {
          path: "/help/:autoplay?",
          name: "Help",
          component: Help,
          props: true
     },
     {
          path: "/referral/:id?",
          name: "referral",
          component: CodeScreen,
          props: true
     },
];

const router = new VueRouter({
     mode: "history",
     base: process.env.BASE_URL,
     routes,

     scrollBehavior(to, from, savedPosition) {
          if (to.params.scroll) {
               // return {
               //      selector: to.params.scroll,
               // };
               return savedPosition;
          }
          if (savedPosition) {
               return savedPosition;
          } else {
               return { x: 0, y: 0 };
          }
     },
});

export default router;
