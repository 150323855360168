<!-- <template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">Help</h1>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container header-content">
        <h1 class="welcome-text">Signup Video</h1>

        <div id="help" class="thankYou">
          <video id="videoPlayer" src="../assets/video/traning.mp4" controls muted></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['autoplay'],
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/help/help_bg.png");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;

  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.container {
  padding: 130px 0px;
  max-width: 1030px;
  margin: auto;
  // padding-left: 16px;
  // padding-right: 16px;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }

  @media screen and (max-width: 1070px) {
    padding: 100px 16px;
    max-width: 1030px;
    margin: auto;
  }
}
.header-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 800;
    font-size: 35px;
    margin-bottom: 60px;

    @media screen and (max-width: 600px) {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 16px;
    }
  }

  .thankYou {
    margin: auto;
    max-width: 450px;
    overflow: hidden;
    border-radius: 1rem;

    img {
      width: 100%;
      height: auto;
    }
  }
}
</style> -->





<template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">Help</h1>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container header-content">
        <h1 class="welcome-text">Signup Video</h1>

        <div id="help" class="thankYou">

          <video
            id="videoPlayer"
            src="../assets/video/trainingVideoNew3.mp4"
            :autoplay="autoplay"
            controls
            muted
            ref="videoPlayer"
          ></video>
          <!-- <video id="videoPlayer" src="../assets/video/traning.mp4" controls muted></video> -->

        </div>

        <!-- <a class="contect-com" href="mailto:contactus@nestease.com" target="_blank">contactus@nestease.com</a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['autoplay'],
  watch: {
    autoplay(newVal) {
      if (newVal) {
        this.scrollToVideoPlayer();
        this.playVideo();
      }
    },
  },
  methods: {
    // playVideo() {
    //   const video = this.$refs.videoPlayer;
    //   if (video) {
    //     video.play();
    //   }
    // },
    scrollToVideoPlayer() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
  },
  mounted() {
    if (this.autoplay) {
      setTimeout(()=>{
        this.scrollToVideoPlayer();

      },[1000])
      // this.playVideo();
    }
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/help/help_bg.png");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;

  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.container {
  padding: 130px 0px;
  max-width: 1030px;
  margin: auto;
  // padding-left: 16px;
  // padding-right: 16px;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }

  @media screen and (max-width: 1070px) {
    padding: 100px 16px;
    max-width: 1030px;
    margin: auto;
  }
}
.header-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 800;
    font-size: 35px;
    margin-bottom: 60px;

    @media screen and (max-width: 600px) {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 16px;
    }
  }

  .thankYou {
    margin: auto;
    max-width: 450px;
    overflow: hidden;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      width: 100%;
      height: auto;
    }
  }
}
</style>
